'use client';

import clsx from 'clsx';

// import { SwirlyDoodle } from '@/images/icons';
import { CheckIcon } from '@heroicons/react/24/solid';
import { Button } from '@/components/Button';
import { Container } from '@/components/Container';
import { useTranslations } from 'use-intl';
function Plan({
  name,
  price,
  description,
  href,
  features,
  featured = false,
  buttonText = 'Get started',
  additionalFeatures = '',
  additionalPricingInfo = '',
  footer = ''
}: {
  name: string;
  price: string | JSX.Element;
  description: string;
  href: string;
  features: Array<string>;
  featured?: boolean;
  buttonText?: string;
  additionalFeatures?: string;
  additionalPricingInfo?: string;
  footer?: string;
}) {
  return <section className={clsx('flex flex-col rounded-3xl px-6 sm:px-8', featured ? 'order-first bg-blue-600 py-8 lg:order-none' : 'lg:py-8')} data-sentry-component="Plan" data-sentry-source-file="Pricing.tsx">
      <h3 className="mt-5 font-display text-4xl text-white">{name}</h3>
      <p className={clsx('mt-2 text-base', featured ? 'text-white' : 'text-slate-400')}>
        {description}
      </p>
      <p className="font-display text-4xl mt-4 font-light tracking-tight text-white">
        {price}{' '}
        {additionalPricingInfo && <span className="text-xl text-white/90">{additionalPricingInfo}</span>}
      </p>

      <Button href={href} variant={featured ? 'solid' : 'outline'} color="white" className="mt-8" aria-label={`Get started with the ${name} plan for ${price}`} data-sentry-element="Button" data-sentry-source-file="Pricing.tsx">
        {buttonText ? buttonText : 'Get started'}
      </Button>
      {additionalFeatures && <p className={clsx('mt-6 text-sm font-semibold', featured ? 'text-white' : 'text-slate-400')}>
          {additionalFeatures}
        </p>}

      <ul role="list" className={clsx('mt-4 flex flex-col gap-y-3 text-sm', featured ? 'text-white' : 'text-slate-200')}>
        {features.map(feature => <li key={feature} className="flex">
            {/* <CheckIcon className={featured ? 'text-white' : 'text-slate-400'} /> */}
            <CheckIcon className={clsx('h-5 w-5', featured ? 'text-white' : 'text-slate-400')} />
            <span className="ml-4" dangerouslySetInnerHTML={{
          __html: feature
        }} />
          </li>)}
      </ul>

      {footer && <p className={clsx('mt-6 text-sm font-semibold text-slate-300')}>
          {footer}
        </p>}
    </section>;
}
export function Pricing() {
  const t = useTranslations('Pricing');
  return <section id="pricing" aria-label="Pricing" className="bg-slate-900 py-20 sm:py-32" data-sentry-component="Pricing" data-sentry-source-file="Pricing.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="Pricing.tsx">
        <div className="md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            <span className="relative whitespace-nowrap">
              {/* <SwirlyDoodle className="absolute left-0 top-1/2 h-[1em] w-full fill-blue-400" /> */}
              <span className="relative">{t('pricing-heading')}</span>
            </span>{' '}
            {t('pricing-heading2')}
          </h2>
          <p className="mt-4 text-lg text-slate-400">{t('pricing-slogan')}</p>
        </div>
        <div className="-mx-4 mt-16 grid max-w-2xl grid-cols-1 gap-y-10 sm:mx-auto lg:-mx-8 lg:max-w-none lg:grid-cols-3 xl:mx-0 xl:gap-x-8">
          <Plan name="Starter" price="Free" description="Perfect for small business to discover Generative AI." href="https://app.ragen.ai/sign-up" buttonText="Start for free" features={['1 member', 'Intelligent Assistant', '<span class="font-bold">Basic RAG</span>', 'Projects Module', 'Knowledge Base Module', 'Limited quota']} data-sentry-element="Plan" data-sentry-source-file="Pricing.tsx" />
          <Plan featured name="Business" price="$19" description="Perfect for small / medium sized businesses." additionalPricingInfo="per user/mo" buttonText="Start free trial" additionalFeatures="Includes everything in Starter plan, plus:" footer="You will be switched to Starter plan after free trial." href="/register" features={['5 members', '<span class="font-bold">AI Chatbot</span> on your website', '<span class="font-bold">Advanced RAG</span>', 'Import data from any URL', 'OpenRouter integration', 'Ollama integration', 'REST API', 'More quota']} data-sentry-element="Plan" data-sentry-source-file="Pricing.tsx" />
          <Plan name="Enterprise" price="Custom" description="For even the biggest enterprise companies with many teams and departments." additionalFeatures="Includes everything in Pro plan, plus:" buttonText="Contact us" href="#contact" features={['SharePoint integration', 'Google Drive integration', '<span class="font-bold">Agentic RAG</span>', 'AWS Bedrock integration', 'Azure OpenAI integration', 'Fireworks AI integration', 'More quota']} data-sentry-element="Plan" data-sentry-source-file="Pricing.tsx" />
        </div>
      </Container>
    </section>;
}