'use client';

import Image, { type ImageProps } from 'next/image';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import clsx from 'clsx';
import { useTranslations } from 'use-intl';
import { Container } from '@/components/Container';
import upload_feature from '@/images/screenshots/upload_feature.png';
import create_doc_feature from '@/images/screenshots/create_doc_feature.png';
import browse_documents from '@/images/screenshots/browse_documents.png';
import { ReportingIcon, InventoryIcon, ContactsIcon } from '@/images/icons';
interface Feature {
  name: React.ReactNode;
  summary: string;
  description: string;
  image: ImageProps['src'];
  icon: React.ComponentType;
}
function getTranslatedFeatures(t: (key: string) => string): Array<Feature> {
  return [{
    name: t('upload-feat'),
    summary: t('upload-feat-summary'),
    description: t('upload-feat-desc'),
    image: upload_feature,
    icon: ReportingIcon
  }, {
    name: t('create-feat'),
    summary: t('create-feat-summary'),
    description: t('create-feat-desc'),
    image: create_doc_feature,
    icon: ContactsIcon
  }, {
    name: t('browse-feat'),
    summary: t('browse-feat-summary'),
    description: t('browse-feat-desc'),
    image: browse_documents,
    icon: InventoryIcon
  }];
}
function Feature({
  feature,
  isActive,
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'> & {
  feature: Feature;
  isActive: boolean;
}) {
  return <div className={clsx(className, !isActive && 'opacity-75 hover:opacity-100')} {...props} data-sentry-component="Feature" data-sentry-source-file="AssistantFeaturesSecondary.tsx">
      <div className={clsx('w-9 rounded-lg', isActive ? 'bg-indigo-600' : 'bg-slate-500')}>
        <svg aria-hidden="true" className="h-9 w-9" fill="none" data-sentry-element="svg" data-sentry-source-file="AssistantFeaturesSecondary.tsx">
          <feature.icon data-sentry-element="unknown" data-sentry-source-file="AssistantFeaturesSecondary.tsx" />
        </svg>
      </div>
      <h3 className={clsx('mt-6 text-md font-medium', isActive ? 'text-indigo-600' : 'text-slate-600')}>
        {feature.name}
      </h3>
      {/* <p className="mt-2 font-display text-xl text-slate-900">
        {feature.summary}
       </p> */}
      <p className="mt-4 text-md text-slate-600">{feature.description}</p>
    </div>;
}
function FeaturesMobile({
  features
}: {
  features: Array<Feature>;
}) {
  return <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden" data-sentry-component="FeaturesMobile" data-sentry-source-file="AssistantFeaturesSecondary.tsx">
      {features.map(feature => <div key={feature.summary}>
          <Feature feature={feature} className="mx-auto max-w-2xl" isActive />
          <div className="relative mt-10 pb-10">
            <div className="absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6" />
            <div className="relative mx-auto w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
              <Image className="w-full" src={feature.image} alt="" sizes="52.75rem" unoptimized />
            </div>
          </div>
        </div>)}
    </div>;
}
function FeaturesDesktop({
  features
}: {
  features: Array<Feature>;
}) {
  return <TabGroup className="hidden lg:mt-20 lg:block" data-sentry-element="TabGroup" data-sentry-component="FeaturesDesktop" data-sentry-source-file="AssistantFeaturesSecondary.tsx">
      {({
      selectedIndex
    }) => <>
          <TabList className="grid grid-cols-3 gap-x-8">
            {features.map((feature, featureIndex) => <Feature key={feature.summary} feature={{
          ...feature,
          name: <Tab className="ui-not-focus-visible:outline-none">
                      <span className="absolute inset-0" />
                      {feature.name}
                    </Tab>
        }} isActive={featureIndex === selectedIndex} className="relative" />)}
          </TabList>
          <TabPanels className="relative mt-20 overflow-hidden rounded-4xl bg-slate-200 px-14 py-16 xl:px-16">
            <div className="-mx-5 flex">
              {features.map((feature, featureIndex) => <TabPanel static key={feature.summary} className={clsx('px-5 transition duration-500 ease-in-out ui-not-focus-visible:outline-none', featureIndex !== selectedIndex && 'opacity-60')} style={{
            transform: `translateX(-${selectedIndex * 100}%)`
          }} aria-hidden={featureIndex !== selectedIndex}>
                  <div className="w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
                    <Image className="w-full" src={feature.image} alt="" sizes="52.75rem" unoptimized />
                  </div>
                </TabPanel>)}
            </div>
            <div className="pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-slate-900/10" />
          </TabPanels>
        </>}
    </TabGroup>;
}
export function AssistantFeaturesSecondary() {
  const t = useTranslations('Secondary-features');
  const translatedFeatures = getTranslatedFeatures(t);
  return <section id="secondary-features" aria-label="Features for simplifying everyday business tasks" className="pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32" data-sentry-component="AssistantFeaturesSecondary" data-sentry-source-file="AssistantFeaturesSecondary.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="AssistantFeaturesSecondary.tsx">
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
            {t('easy-management')}
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            {t('easy-management-subtitle')}
          </p>
        </div>
        <FeaturesMobile features={translatedFeatures} data-sentry-element="FeaturesMobile" data-sentry-source-file="AssistantFeaturesSecondary.tsx" />
        <FeaturesDesktop features={translatedFeatures} data-sentry-element="FeaturesDesktop" data-sentry-source-file="AssistantFeaturesSecondary.tsx" />
      </Container>
    </section>;
}